import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, catchError, finalize, map } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { JwtHelperService } from "@auth0/angular-jwt";
import { jwtDecode } from "jwt-decode";
import { ToastrService } from "ngx-toastr";
import { NgRedux } from "@angular-redux-ivy/store";
import { IAppState } from "src/app/app.reducer";
import { SET_ENTITY } from "src/app/app.actions";
import { MatDialog } from "@angular/material/dialog";
import { resetStore } from "src/app/app.store";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });
  baseUrlAuth = environment.backend.baseUrlIam;
  private serviceSignUp = "/oauth2/sign_up";
  private serviceSignIn = "/oauth2/sign_in";
  private serviceSignOut = "/oauth2/sign_out";
  private serviceSocialSignIn = "/oauth2/social/sign_in";
  private serviceValidateCode = "/oauth2/validate";
  _loggingOut = new BehaviorSubject(false);
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  sessionOut = new BehaviorSubject(false);
  helper = new JwtHelperService();
  currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private socialLogin: SocialAuthService,
    private toastrSservice: ToastrService,
    private redux: NgRedux<IAppState>,
    public dialog: MatDialog
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const token = this.getToken();
    const decodedUser = token ? this.decodeToken(token) : null;
    this.currentUserSubject = new BehaviorSubject<any>(decodedUser);
    this.currentUser = this.currentUserSubject.asObservable();
  }
  login(body: object) {
    return this.http.post(`${this.baseUrlAuth}` + this.serviceSignIn, body, {
      headers: this.headers,
    });
  }
  loginWithGoogle(user: object) {
    return this.http.post(
      `${this.baseUrlAuth}` + this.serviceSocialSignIn,
      user,
      {
        headers: this.headers,
      }
    );
  }
  validateCode(body: Object) {
    return this.http.post(
      `${this.baseUrlAuth}` + this.serviceValidateCode,
      body,
      {
        headers: this.headers,
      }
    );
  }
  register(user: object) {
    return this.http.post(`${this.baseUrlAuth}` + this.serviceSignUp, user, {
      headers: this.headers,
    });
  }
  getToken() {
    const cookieValue = this.cookieService.get("access_token");
    return cookieValue ? cookieValue : "";
  }
  getGeneric(url: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.getToken(),
    });
    return this.http.get(`${this.baseUrlAuth}` + url, {
      headers: headers,
    });
  }
  getRefreshToken() {
    const cookieValue = this.cookieService.get("refresh_token");
    return cookieValue ? cookieValue : "";
  }
  isLoggedIn(): boolean {
    const token: String = this.getToken();
    const refreshToken = this.getRefreshToken();
    return token && token.length > 0 ? true : false;
  }
  logout(loggingOut?) {
    if (!this.getToken()) {
      this.router.navigate(["/auth/login"]);
    } else {
      if (this.tokenExpired()) {
        this.sessionOut.next(true);
      } else {
        this.sessionOut.next(false);
      }
      loggingOut
        ? this._loggingOut.next(loggingOut?.getValue())
        : this._loggingOut.next(false);
      return this.http
        .post(
          `${this.baseUrlAuth}` + this.serviceSignOut,
          {
            application_id: "accountic_client_app",
            refresh_token: this.getRefreshToken(),
          },
          {
            headers: this.headers,
          }
        )
        .pipe(
          map(() => {
            this.router.navigate(["/auth/login"]);
            this.cookieService.delete("access_token", "/");
            this.cookieService.delete("refresh_token", "/");
            resetStore();
            localStorage.clear()
            this.dialog.closeAll();
            this.socialLogin.signOut();
            this.currentUserSubject.next(null);
            // this.socialLogin
            //   .signOut(true)
            //   .catch((error) => {
            //     console.log("error on logging out: " + error);
            //   });
            return true;
          }),
          catchError((): any => {
            this.router.navigate(["/auth/login"]);
            this.cookieService.delete("access_token", "/");
            this.cookieService.delete("refresh_token", "/");
            this.dialog.closeAll();
            this.router.navigate(["/auth/login"]);
            return false;
          })
        )
        .subscribe();
    }
  }
  tokenExpired() {
    let token = this.getToken();
    return this.helper.isTokenExpired(token);
  }
  getCurrentUser() {
    const token = this.cookieService.get("access_token");
    if (token) {
      const user = jwtDecode(token);
      return user;
    }
  }
  decodeToken(token) {
    return jwtDecode(token);
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
}
