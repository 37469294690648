import { Injectable } from '@angular/core'
import { AuthService } from './auth/auth.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { CookieService } from 'ngx-cookie-service'
import { extractSegment, extractValuesFromJson } from '../utilities/decodeCloudfrontToken'

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  baseUrl = environment.backend.baseUrlAccountic
  baseUrlImage = environment.backend.baseUrlImageService
  cloudPolicy: string
  cloudSignature: string
  cloudKeyPairId: string
  baseUrlUploadImage: any
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private cookieService: CookieService,
  ) {}
  getGenericById(url: any, id?: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getToken(),
    })
    return this.http.get(`${this.baseUrl}` + url + `/${id}`, {
      headers: headers,
    })
  }

  getGeneric(url: any, token?): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: this.cookieService.check('access_token')
        ? 'Bearer ' + this.authService.getToken()
        : 'Bearer ' + token,
    })
    return this.http.get(`${this.baseUrl}` + url, {
      headers: headers,
    })
  }
  putGeneric(url: any, object: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getToken(),
    })
    return this.http.put(`${this.baseUrl}` + url, object, {
      headers: headers,
    })
  }
  putCloudFront(file: File, fileName): Observable<any> {
    this.cookieService.check("cloudFrontPolicy")
      ? console.log(
          extractSegment(this.cookieService.get("cloudFrontPolicy")).resource
        )
      : null;
    const headers = new HttpHeaders({
      'Content-Type': 'text/plain',
    }).append(
      'Cookie',
      `CloudFront-Policy=${this.cookieService.get(
        'cloudFrontPolicy',
      )};CloudFront-Signature=${this.cookieService.get(
        'cloudFrontSignature',
      )};CloudFront-Key-Pair-Id=${this.cookieService.get(
        'cloudFrontKeyPairId',
      )}`,
    )
    return this.http.put(
      `${
        extractValuesFromJson(this.cookieService.get('cloudFrontPolicy'))
          .resource
      }` + fileName,
      file,
      {
        headers: headers,
        withCredentials: true,
      },
    )
  }
  getInvoiceGeneric(url?: any): Observable<any> {
    return this.http.get(`${url}`, {
      responseType: 'blob',
      withCredentials: true
    })
  }
  postGeneric(url: any, object?: any, token?) {
    const headers = new HttpHeaders({
      Authorization: this.cookieService.check('access_token')
        ? 'Bearer ' + this.authService.getToken()
        : 'Bearer ' + token,
    })
    return this.http.post(`${this.baseUrl}` + url, object, {
      headers: headers,
    })
  }
  deleteGeneric(url: any, object?: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getToken(),
    })
    return this.http.delete(`${this.baseUrl}` + url, {
      headers: headers,
      body: object,
    })
  }
  uploadImages(url: any, object?: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getToken(),
    }).append(
      'Cookies',
      `CloudFront-Policy=${this.cookieService.get(
        'cloudFrontPolicy',
      )};CloudFront-Signature=${this.cookieService.get(
        'cloudFrontSignature',
      )};CloudFront-Key-Pair-Id=${this.cookieService.get(
        'cloudFrontKeyPairId',
      )}`,
    )
    return this.http.post(`${this.baseUrl}` + url, object, {
      headers: headers,
    })
  }
  patchGeneric(url: any, object?: any, token?): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: this.cookieService.check('access_token')
        ? 'Bearer ' + this.authService.getToken()
        : 'Bearer ' + token,
      'Content-Type': 'application/json',
    })
    return this.http.patch(`${this.baseUrl}` + url, object, {
      headers: headers,
    })
  }
  downloadInvoice(url: any): Observable<Blob> {
    const headers = new HttpHeaders({
      'accept': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
      });

    return this.http.get(`${this.baseUrl}` + url,  { headers, responseType: 'blob' })
  }
}
