import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "src/app/shared/services/auth/auth.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken()
    if ((token && this.authService.tokenExpired() || !token)) {
      this.SessionExpired();
    }
    return next.handle(req);
  }
  private SessionExpired() {
    return this.authService.logout();
  }
}
